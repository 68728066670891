//Typo Grid

p,
ul,
ol,
pre,
table,
blockquote {
    margin-bottom: 25px;
    margin-top: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
    margin-bottom: 0;
    margin-top: 0;
}

hr,
.hr {
    border: 1px solid;
    margin: -1px 0;
}

a,
b,
i,
strong,
em,
small,
code {
    line-height: 1.4;
}

sub,
sup {
    line-height: 1.4;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}
