//Links

.text-link-style,
.md-text a {
    color: $cl-primary-00;
    cursor: pointer;
    text-decoration: none;
}

.buy-btn-wrapper {
    transform: translateY(50%);
    justify-content: flex-end;
    display: flex;
}

.buy-btn {
    display: flex;
    align-items: center;
    font-weight: $font-weight-regular;

    &:hover {
        &::before {
            transform: scale(1);
        }

        &::after {
            animation: pulse .4s;
            background-color: $cl-anthrazit-dark;
        }
    }

    &::before {
        position: absolute;
        content: '';
        height: 7rem;
        width: 7rem;
        display: block;
        background-color: $cl-anthrazit-dark;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
        border-radius: 100%;
        text-align: center;
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        margin-left: 2rem;
        right: 0;
        transform: scale(0);
        transition: all .3s ease-in-out;
        z-index: 2;
    }

    &::after {
        content: '';
        height: 7rem;
        width: 7rem;
        display: block;
        background-color: $cl-primary-00;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
        border-radius: 100%;
        text-align: center;
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        margin-left: $spacing-2x;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.5);
    }
    100% {
        opacity: 0;
    }
}
