.publishing-page {
    background: $cl-anthrazit-light-05;
    overflow: hidden;

    .text-img-combi {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media only screen and (min-width : $bp-l) {
            flex-direction: row;

            &__text {
                width: 50%;
                margin-right: 10%;
            }

            &__img {
                width: 50%;
            }
        }
    }

    .publishing-slider-wrapper {
        position: relative;

        .publishing-slider-prev,
        .publishing-slider-next {
            background: $cl-anthrazit-dark;
            position: absolute;
            height: 4rem;
            width: 4rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;

            @media only screen and (min-width : $bp-l) {
                height: 7rem;
                width: 7rem;
            }

            &:hover {
                background: $cl-anthrazit;
                transition: background .1s linear;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 50%;
                border: solid $cl-white;
                border-width: 0 .1rem .1rem 0;
                display: inline-block;
                padding: .5rem;
                transform: translate(50%, -70%) rotate(135deg);
            }
        }

        .publishing-slider-next {
            right: 0;

            @media only screen and (min-width : $bp-l) {
                right: 10rem;
            }

            &::after {
                transform: translate(50%, -70%) rotate(-45deg);
            }
        }
    }

    .artists {
        position: relative;

        @media only screen and (min-width : $bp-l) {
            padding-right: 10rem;
        }

        .slick-track {
            padding: 2rem 0;
        }

        &::before {
            content: '';
            display: block;
            background: $cl-primary-00;
            height: calc(100% - 20rem);
            position: absolute;
            top: 10rem;
            width: 100%;
        }

        &__item {
            display: inline-block;
            position: relative;
            margin: 0 4rem;
            background: $cl-white;
            padding: $spacing-2x $spacing-2x $spacing-1x $spacing-2x;
            z-index: 2;

            &__top-line {
                position: absolute;
                top: 0;
                transform: translateY(-55%);
                display: none;

                @media only screen and (min-width : $bp-l) {
                    display: block;
                    font-size: $font-size-ml;
                }
            }

            &__title {
                position: absolute;
                color: $cl-white;
                font-size: $font-size-xl;
                bottom: 25%;
                left: 50%;
                transform: translate(-50%, -25%);
                text-align: center;
                line-height: $line-height-base;

                span {
                    background-image: linear-gradient(to right, $cl-primary-00 0%, $cl-primary-00 100%);
                    background-repeat: repeat-x;
                    background-position: 0 100%;
                    background-size: 100% 25%;
                }
            }

            .social-links {
                margin-top: $spacing-1x;
                text-align: center;
                color: $cl-primary-00;
                font-weight: $font-weight-regular;

                &__item {
                    font-size: $font-size-base;
                    display: block;

                    &:hover {
                        color: $cl-anthrazit-dark;
                    }

                    @media only screen and (min-width : $bp-m) {
                        display: inline-block;

                        &:nth-child(2) {
                            &::before,
                            &::after {
                                content: '—';
                                margin: 0 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
