.header {
    background: $cl-white;
    width: 100%;
    z-index: 10;
    height: 6rem;
    position: fixed;
    top: 0;

    @media only screen and (min-width : $bp-l) {
        position: fixed;
        right: 0;
        height: 100%;
        width: 10rem;

        &::after {
            content: '';
            height: 100%;
            width: 10rem;
            background: $cl-white;
            display: block;
            top: 0;
            position: fixed;
            z-index: 1;
        }
    }

    &__logo {
        display: block;
        width: 10rem;
        height: 6rem;
        padding: 1rem;
        z-index: 2;
        position: relative;

        svg {
            height: 100%;
            width: 100%;
        }

        @media only screen and (min-width : $bp-l) {
            transform: rotate(90deg);
            margin-top: 4rem;
            transition: .1s ease-in-out;

            &:hover {
                transform: rotate(90deg) scale(1.1);
            }
        }
    }

    .menu-toggle {
        background: $cl-primary-00;
        height: 6rem;
        display: block;
        width: 6rem;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 2;

        @media only screen and (min-width : $bp-l) {
            height: 20%;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            transition: all .2s cubic-bezier(.7, .06, .31, .84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;

            &:hover {
                width: calc(100% + 1rem);

                .hamburger span:nth-child(2) {
                    transform: translateX(-.7rem);
                }
            }
        }

        span {
            transition: .15s ease-in-out;
            display: block;
            background: $cl-white;
        }

        .hamburger {
            position: absolute;
            height: 2rem;
            width: 3rem;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            @media only screen and (min-width : $bp-l) {
                height: 2.7rem;
                width: 4rem;
            }

            span {
                transition: .25s ease-in-out;
                width: 100%;
                position: relative;
                height: .2rem;

                &:nth-child(1) {
                    transition-delay: .4s;
                }

                &:nth-child(2) {
                    transition: width .25s ease-in-out .525s, transform .2s cubic-bezier(.7, .06, .31, .84);
                    margin: .6rem 0;

                    @media only screen and (min-width : $bp-l) {
                        margin: 1rem 0;
                    }
                }

                &:nth-child(3) {
                    transition-delay: .65s;
                }
            }
        }

        .cross {
            position: absolute;
            height: 4rem;
            width: 4rem;
            transform: translate(-50%, -50%) rotate(45deg);
            top: 50%;
            left: 50%;

            span {
                &:nth-child(1) {
                    height: 0%;
                    width: .2rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition-delay: 0s;
                }

                &:nth-child(2) {
                    width: 0%;
                    height: .2rem;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition-delay: .25s;
                }
            }
        }

        &--is-open {
            .hamburger {
                span {
                    width: 0%;

                    &:nth-child(1) {
                        transition-delay: 0s;
                    }

                    &:nth-child(2) {
                        transition-delay: .125s;
                    }

                    &:nth-child(3) {
                        transition-delay: .25s;
                    }
                }
            }

            .cross {
                span {
                    &:nth-child(1) {
                        height: 80%;
                        transition-delay: .625s;
                    }

                    &:nth-child(2) {
                        width: 80%;
                        transition-delay: .375s;
                    }
                }
            }
        }
    }

    &__shop-cta {
        bottom: 1rem;
        position: absolute;
        text-align: center;
        width: 70%;
        background: $cl-anthrazit;
        margin: 0 15%;
        line-height: 4rem;
        display: none;
        visibility: none;
        color: $cl-white;

        @media only screen and (min-width : $bp-l) {
            z-index: 2;
            display: block;
            visibility: visible;
            transition: .1s ease-in-out;

            &:hover {
                background: $cl-anthrazit-light-10;
                transform: scale(1.1);
            }
        }
    }
}

.header.is-hidden {
    transform: translateY(-100%);
}
