.tour {

    .glitch {
        position: fixed;
        top: 0;
        left: 0;

        div::after {
            opacity: .1;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }

    &__title {
        color: $cl-white;

        &::after {
            background-color: $cl-white;
        }
    }

    &__text-top {
        width: 80%;
        color: $cl-white;
        font-size: $font-size-ml;
        margin-bottom: 6rem;
        
        a {
            box-shadow: 0 3px 0 $cl-white;
            cursor: pointer;
        }

        @media only screen and (min-width : $bp-l) {
            font-size: $font-size-xl;
        }
    }

    &__list {
        overflow: hidden;
        margin-bottom: 0;

        &__event {
            color: $cl-white;
            font-size: $font-size-l;
            font-weight: $font-weight-light;
            margin-bottom: 6rem;

            &__date {
                display: block;
                font-size: $font-size-ml;
            }

            &__title {
                @media only screen and (min-width : $bp-l) {
                    margin-left: 2rem;
                    font-size: $font-size-xxl;
                }
            }
        }
    }

    &__additional-infos {
        margin-top: $spacing-8x;
        width: 32rem;
        color: $cl-white;
        line-height: $line-height-base;
    }
}
