.default-page {
    @media only screen and (min-width : $bp-m) {
        width: 80%;
    }

    .md-text {
        iframe {
            max-width: 100%;
        }
    }
}
