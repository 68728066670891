.exotic-seed {
    background: $cl-anthrazit-light-05;
    overflow: hidden;

    .text-img-combi {

        &__img {
            img {
                background-color: transparent;
            }
        }

        @media only screen and (min-width : $bp-l) {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-direction: row;

            &__text {
                width: 50%;
                margin-right: 10%;
            }

            &__img {
                width: 50%;
            }
        }

        &--intro {
            align-items: end;

            .text-img-combi__img {
                margin-top: -6rem;
                margin-bottom: -15rem;
                padding: 0 10%;

                @media only screen and (min-width : $bp-l) {
                    padding: 0;
                }
            }

            .buy-btn-wrapper {
                margin-bottom: 10rem;
            }
        }

        &--v2 {
            margin-bottom: 0;
            .text-img-combi__img-title {
                width: 100%;
                color: $cl-white;
                display: inline-block;
                margin-bottom: $spacing-1x;

                @media only screen and (min-width : $bp-l) {
                    font-size: $font-size-ml;
                }
            }

            .text-img-combi__img-container {
                .text-img-combi__img {
                    margin-left: calc(-1rem + -5%);
                    float: left;
                    width: 100%;

                    @media only screen and (min-width : $bp-l) {
                        margin-left: 0;
                    }
                }
            }

            .text-img-combi__text {
                background: $cl-white;
                padding: 2rem;
                margin-right: calc(-1rem + -5%);
                float: right;
                margin-top: -15vw;
                width: 100%;
                z-index: 1;

                @media only screen and (min-width : $bp-m) {
                    width: 80%;
                    padding: 4rem;
                    margin-top: -20vw;
                }

                @media only screen and (min-width : $bp-l) {
                    margin: 0;
                    margin-left: -15rem;
                    max-width: 50rem;
                }
            }
        }

        &--v3 {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width : $bp-l) {
                flex-direction: row;
            }

            .text-img-combi__text {
                order: 2;
                color: $cl-white;

                @media only screen and (min-width : $bp-l) {
                    order: 1;
                    width: 100%;
                }

                @media only screen and (min-width : $bp-xl) {
                    width: 50%;
                }
            }

            .text-img-combi__img-container {
                order: 1;
                position: relative;
                margin-bottom: $spacing-2x;

                @media only screen and (min-width : $bp-l) {
                    order: 2;
                }

                @media only screen and (min-width : $bp-xxl) {
                    margin-bottom: -10rem;
                }

                .text-img-combi__img-container__nr-one {
                    transform-origin: 0%;
                    transform: scale(.5) translateX(-50%);
                    position: absolute;
                    bottom: 12%;
                    left: 50%;
                    color: $cl-white;
                    font-weight: $font-weight-bold;
                    width: 160%;
                    text-align: center;

                    @media only screen and (min-width : $bp-m) {
                        //top: 40%;
                        transform: scale(.7) translateX(-50%);
                    }

                    @media only screen and (min-width : $bp-l) {
                        //top: 15%;
                        transform: scale(.6) translateX(-50%);
                        width: 200%;
                    }

                    @media only screen and (min-width : $bp-xl) {
                        //top: 30%;
                        transform: scale(.8) translateX(-50%);
                        width: 100%;
                    }

                    // span:nth-child(1) {
                    //     font-size: 250px;
                    // }

                    // span:nth-child(2) {
                    //     font-size: 150px;
                    // }

                    span {
                        font-size: $h1-font-size;
                        display: block;
                        width: fit-content;
                        position: relative;
                        margin: 0 auto;

                        &::after {
                            content: '';
                            transition: .1s ease-in-out;
                            display: block;
                            height: 25%;
                            width: 100%;
                            background: $cl-primary-00;
                            position: absolute;
                            bottom: .5rem;
                            margin-top: 0;
                            z-index: -1;
                        }
                    }
                }

                .text-img-combi__img {
                    width: 100%;
                }
            }
        }
    }

    &__content {
        background: $cl-primary-00;
        padding-top: 16rem;
        padding-bottom: 4rem;
        margin-bottom: 8rem;

        .banderole-container {
            clear: both;
            margin-top: 4rem;

            @media only screen and (min-width : $bp-l) {
                margin-top: 8rem;
            }

            .banderole {
                color: #ffffff40;
            }
        }

        @media only screen and (min-width : $bp-l) {
            padding-top: 20rem;
            padding-bottom: 20rem;
            margin-bottom: 24rem;
        }

        @media only screen and (min-width : $bp-xxl) {
            padding-bottom: 0;
        }
    }

    .test-video {
        position: relative;
        display: block;
        cursor: pointer;

        &:hover {
            svg {
                fill: $cl-white;
                background: $cl-primary-00;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $cl-white;
            width: 14vw;
            height: 14vw;
            padding: 5vw;
            fill: $cl-primary-00;
            transition: all .2s linear;

            @media only screen and (min-width : $bp-l) {
                width: 10vw;
                height: 10vw;
                padding: 3.5vw;
            }
        }
    }
}
