.footer {
    background: $cl-anthrazit;
    color: $cl-white;
    position: relative;
    padding-top: 4rem;

    @media only screen and (min-width : $bp-l) {
        padding-top: 6rem;
    }

    .bp-logo {
        fill: $cl-white;
        width: 10rem;
        margin: 0 auto;
        display: block;
        margin-bottom: 4rem;

        @media only screen and (min-width : $bp-l) {
            position: absolute;
            left: calc(50% - 10rem);
            transition: transform .2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__nav-container {
        @media only screen and (min-width : $bp-l) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .footer__nav {
        font-size: $font-size-ml;
        text-align: center;
        margin-bottom: 2rem;
        order: 1;

        a {
            display: block;
        }

        @media only screen and (min-width : $bp-l) {
            a {
                text-align: left;
                margin-bottom: 1rem;
                position: relative;
                z-index: 1;
                width: fit-content;

                &::after {
                    content: '';
                    transition: all .2s cubic-bezier(.7, .06, .31, .84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
                    width: 0;
                }

                &:hover {
                    &::after {
                        content: '';
                        background: $cl-anthrazit-dark;
                        display: block;
                        width: 105%;
                        height: 35%;
                        position: absolute;
                        bottom: 0;
                        z-index: -1;
                        left: -2.5%;
                    }
                }
            }
        }
    }

    &__icon-list {
        display: flex;
        flex-direction: row;
        padding: 0 5%;
        justify-content: center;
        order: 2;

        @media only screen and (min-width : $bp-l) {
            padding: 0;
        }

        li {
            border: 1px solid $cl-white;
            margin-top: 2rem;
            width: 6rem;
            height: 6rem;

            &:hover {
                svg {
                    padding: 1.35rem;
                }
            }

            &:nth-child(2) {
                margin: 2rem 1rem;
            }

            @media only screen and (min-width : $bp-l) {
                width: 6rem;
                height: 6rem;
            }

            svg {
                fill: $cl-white;
                padding: 1.5rem;
                width: 100%;
                height: 100%;
                transition: padding .1s ease-in-out;
            }
        }
    }

    &__bottom {
        background: $cl-anthrazit-dark;
        padding: 1rem 0;

        .container {
            display: flex;
            flex-direction: column;

            @media only screen and (min-width : $bp-l) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        @media only screen and (min-width : $bp-l) {
            margin-top: 3rem;
        }

        span,
        a {
            text-align: center;
            margin: 1rem 0;
        }
    }
}
