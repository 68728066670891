//Headlines

h1,
.h1-style,
%h1-style {
    font-weight: $font-weight-light;
    line-height: $line-height-base;
    margin-bottom: 12rem;
    position: relative;
    font-size: $font-size-xl;

    @media only screen and (min-width : $bp-l) {
        font-size: $h1-font-size;
        margin-bottom: 15rem;
    }

    &::after {
        content: '';
        background: $cl-anthrazit-dark;
        height: .1rem;
        width: 10rem;
        display: block;
        bottom: -$spacing-4x;
        position: absolute;
    }
}

h2,
.h2-style,
%h2-style {
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
    line-height: $line-height-base;
    margin-bottom: $spacing-down-1x;
}

h3,
.h3-style,
%h3-style {
    font-size: $h3-font-size;
    line-height: $line-height-base;
    margin-bottom: $spacing-down-1x;
}

h4,
.h4-style,
%h4-style {
    font-size: $h4-font-size;
    line-height: $line-height-base;
    margin-bottom: $spacing-down-1x;
}
