.home {
    .intro {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width : $bp-l) {
            flex-direction: row;
            margin-right: 10rem;
        }

        &__text {
            width: 100%;
            padding: 0 5%;
            order: 2;
            position: relative;

            @media only screen and (min-width : $bp-l) {
                order: 2;
                width: 100%;
                padding: 5rem 10% 10rem 5%;
                background: $cl-white;
            }

            .scroll-down {
                height: 7rem;
                width: 7rem;
                display: none;
                background-color: $cl-primary-00;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
                border-radius: 100%;
                text-align: center;
                background-size: 2rem;
                background-repeat: no-repeat;
                background-position: 50%;
                right: -3.5rem;
                transform: rotate(90deg) translateX(-50%);
                top: 50%;
                position: absolute;
                transition: background-color .2s linear;
                box-shadow: 4px 0 12px 0 #d81301d4;

                &:hover {
                    background-color: $cl-primary-00--darken;
                }

                @media only screen and (min-width : $bp-l) {
                    display: block;
                }
            }
        }

        &__img {
            width: 90%;
            margin: 0 5%;
            order: 1;

            @media only screen and (min-width : $bp-l) {
                order: 2;
                width: 120%;
                margin: 0;
            }

            div {
                opacity: 1;
                background: none;

                @media only screen and (min-width : $bp-l) {
                    opacity: 1;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100%;
                }
            }

            img {
                width: 100%;

                @media only screen and (min-width : $bp-l) {
                    opacity: 0;
                }
            }
        }
    }

    .news-feed {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        .buy-btn-wrapper {
            margin-bottom: 4rem;
        }

        &__item {
            background: $cl-white;
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 4rem;
            width: 100%;

            @media only screen and (min-width : $bp-m) {
                width: 48.5%;
            }

            a {
                padding: 2rem;
                display: block;
                height: 100%;
            }

            img {
                margin-bottom: $spacing-2x;
            }

            &:nth-child(even) {
                margin-left: 1.25%;
            }

            &:nth-child(odd) {
                margin-right: 1.25%;
            }

            h3 {
                &::after {
                    content: '';
                    height: .1rem;
                    width: 5rem;
                    background: $cl-anthrazit-dark;
                    display: block;
                    margin-top: 2rem;
                }
            }

            &__read-more {
                position: absolute;
                bottom: -1rem;
                right: 2rem;

                &::before {
                    content: '';
                    height: .1rem;
                    width: 100%;
                    background: $cl-anthrazit-dark;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }

                &::after {
                    content: '';
                    border: solid $cl-anthrazit-dark;
                    border-width: 0 .1rem .1rem 0;
                    display: inline-block;
                    padding: 3px;
                    margin: 0 0 .2rem 1rem;
                    transform: rotate(-45deg);
                }

                &:hover {
                    color: $cl-primary-00;

                    &::before {
                        background: $cl-primary-00;
                    }

                    &::after {
                        border-color: $cl-primary-00;
                    }
                }
            }
        }
    }

    .instagram-feed {
        ul {
            display: flex;
            flex-direction: column;

            @media only screen and (min-width : $bp-m) {
                flex-direction: row;
            }

            li {
                width: 100%;
                margin-bottom: 1rem;

                @media only screen and (min-width : $bp-m) {
                    width: 30%;
                    &:nth-child(2) {
                        margin: 0 5%;
                    }
                }

                a {
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    .intro-slider {
        padding-top: 2rem;

        .slick-list {
            padding-bottom: 4rem;
        }

        margin: 15rem 0;

        &__image {
            @media only screen and (min-width : $bp-xxl) {
                width: 70%;
            }
        }

        &__btns {
            @media only screen and (min-width : $bp-l) {
                position: absolute;
                right: 0;
                bottom: -7rem;
                display: flex;
            }

            span {
                background: $cl-anthrazit-dark;
                position: absolute;
                top: -4rem;
                height: 4rem;
                width: 4rem;

                @media only screen and (min-width : $bp-l) {
                    height: 7rem;
                    width: 7rem;
                    top: 0;
                    display: block;
                    position: relative;
                }

                &:hover {
                    background: $cl-anthrazit;
                    transition: background .1s linear;
                }

                &:first-child {
                    left: 0;
                }

                &:first-child,
                &:last-child {
                    margin-right: .1rem;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 50%;
                        border: solid $cl-white;
                        border-width: 0 .1rem .1rem 0;
                        display: inline-block;
                        padding: .5rem;
                        transform: translate(50%, -70%) rotate(135deg);
                    }
                }

                &:last-child {
                    right: 0;

                    &::after {
                        transform: translate(50%, -70%) rotate(-45deg);
                    }
                }
            }
        }

        &__content-box {
            z-index: 2;
            background: $cl-white;
            padding: 2rem;
            position: relative;

            @media only screen and (min-width : $bp-xxl) {
                width: 40%;
                position: absolute;
                right: 0%;
                top: 50%;
                transform: translateY(-50%);
            }

            h3 {
                &::after {
                    content: '';
                    height: .1rem;
                    width: 5rem;
                    background: $cl-anthrazit-dark;
                    display: block;
                    margin-top: 2rem;
                }
            }
        }
    }
}
