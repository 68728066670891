// Font Configuration
// --------------------------------

$primary-font:          'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base:        1.8rem;

// Font Weights
// --------------------------------

$font-weight-light:    300;
$font-weight-regular:  400;
$font-weight-bold:     600;

// Line Heights
// --------------------------------

$line-height-base:     1.6;

//
// --------------------------------
// Font Sizes

$font-size-xs:			  1.2rem;
$font-size-s:            1.4rem;
$font-size-base:         $font-size-base; //18px
$font-size-m:            1.8rem;
$font-size-ml:            2.4rem;
$font-size-l:			  3.6rem;
$font-size-xl:			  4.8rem;
$font-size-xxl:			  5.8rem;

// Heading Sizes

$h1-font-size:            7.2rem;
$h2-font-size:            $font-size-xxl;
$h3-font-size:            2.9rem;
$h4-font-size:            $font-size-base;
