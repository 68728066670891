.cookie-notification {
    background: $cl-white;
    position: fixed;
    max-width: 95%;
    bottom: 1rem;
    left: 1rem;
    z-index: 20;
    padding: 1rem;
    font-size: $font-size-base;
    box-shadow: $shadow-00;

    @media only screen and (min-width : $bp-m) {
        max-width: 32rem;
    }

    p {
        width: calc(100% - 4rem);
    }

    .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 2rem;
        height: 2rem;
        opacity: .3;

        &:hover {
            opacity: 1;
        }

        &::before,
        &::after {
            position: absolute;
            left: 1rem;
            content: ' ';
            height: 2rem;
            width: .2rem;
            background-color: #333;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &__cta-box {
        display: flex;
        flex-direction: column;

        .btn {
            border: 0;
            padding: 1rem;
            font-size: $font-size-base;
            font-weight: $font-weight-light;
            color: $cl-anthrazit-dark;
            margin-bottom: 1rem;
            transition: background .2 linear;

            &:last-child {
                margin-bottom: 0;
            }

            &--primary {
                background: $cl-primary-00;
                color: $cl-white;
                padding: 1rem 7rem;
                width: 100%;
                margin-bottom: 1rem;

                &:hover {
                    background: $cl-primary-00--darken;
                }
            }

            &--secondary {
                font-size: 1.6rem;
                font-weight: 300;
                color: $cl-anthrazit;
                background: $cl-anthrazit-light-05;

                &:hover {
                    background: $cl-anthrazit-light-10;
                }
            }
        }
    }
}
