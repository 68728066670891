.big-list {
    li {
        &::after {
            content: '';
            display: block;
            background: #fff;
            height: .1rem;
            width: 5rem;
            margin-top: 2rem;
        }

        &:last-child {
            margin-bottom: $spacing-8x;
            &::after {
                display: none;
            }
        }
    }
}

ol {
    list-style-position: inside;
}
