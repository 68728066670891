.menu {
    z-index: -1;
    opacity: 0;
    left: 100%;
    transition: all .2s cubic-bezier(.7, .06, .31, .84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
    overflow-y: scroll;
    height: 100vh;
    visibility: hidden;

    .signature {
        display: none;
        visibility: hidden;
    }

    @media only screen and (min-width : $bp-l) {
        overflow-y: unset;

        .signature {
            position: absolute;
            left: 2rem;
            top: 11rem;
            z-index: 2;
            font-weight: $font-weight-regular;
            transform: translate(-50%, 0) rotate(90deg);
        }
    }

    &__title {
        font-size: $font-size-l;
        margin-bottom: 4rem;
        padding-left: 5%;
        z-index: 1;
        &::after {
            bottom: -2rem;
        }

        @media only screen and (min-width : $bp-l) {
            font-size: $h1-font-size;
            display: block;
            padding-left: calc(50% + 2rem);
            margin-bottom: 5vh;
        }
    }

    &--is-open {
        background: $cl-anthrazit-light-10;
        position: fixed;
        top: 6rem;
        left: 0;
        opacity: 1;
        width: 100%;
        display: block;
        visibility: visible;

        @media only screen and (min-width : $bp-l) {
            background: url('../images/herzog-menu.jpg') 55% 50% no-repeat;
            width: calc(100% - 10rem);
            height: 100%;
            top: 0;
            background-size: cover;

            &::after {
                content: '';
                background: url('../images/noiz.gif') 50%;
                display: block;
                height: 100%;
                left: 0;
                opacity: .06;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .grid {
                opacity: 1;
                top: 0;
            }
        }
    }

    &__navigation {
        font-size: $font-size-ml;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width : $bp-l) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 38rem);
            font-size: 2.6rem;
        }

        .menu-link {
            position: relative;
            z-index: 1;
            width: fit-content;

            &::after {
                content: '';
                transition: all .2s cubic-bezier(.7, .06, .31, .84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
                width: 0;
            }

            @media only screen and (min-width : $bp-l) {
                &:hover {
                    &::after {
                        content: '';
                        background: $cl-primary-00--lighten;
                        display: block;
                        width: 105%;
                        height: 35%;
                        position: absolute;
                        bottom: 0;
                        z-index: -1;
                        left: -2.5%;
                    }
                }
            }

            &--is-active {
                &::after {
                    content: '';
                    background: $cl-primary-00--lighten;
                    display: block;
                    width: 105%;
                    height: 35%;
                    position: absolute;
                    bottom: 0;
                    z-index: -1;
                    left: -2.5%;
                }
            }
        }

        &__first-menu,
        &__meta-menu,
        &__second-menu {
            position: relative;
            z-index: 2;
        }

        &__first-menu {
            padding: 0 5%;
            margin-bottom: 1rem;

            @media only screen and (min-width : $bp-l) {
                padding-left: calc(50% + 2rem);
                margin-bottom: 0;
            }
        }

        &__meta-menu {
            font-size: $font-size-s;
            display: flex;
            padding: 2rem 5%;
            width: 100%;
            align-self: flex-end;
            margin-top: auto;

            @media only screen and (min-width : $bp-l) {
                font-size: $font-size-base;
                padding-left: calc(50% + 2rem);
                padding-bottom: 0;
                margin-top: 0;
                height: 100%;

                ul {
                    margin-bottom: .5vh;
                    display: flex;
                    flex-direction: column;
                    align-self: flex-end;
                }
            }

            &__social {
                margin-right: 15%;
            }
        }

        &__second-menu {
            padding: 1rem 5% 1rem 5%;
            margin-bottom: 10%;
            background: $cl-white;

            li {
                p {
                    display: none;
                }
                .menu__navigation__second-menu__image {
                    display: none;
                }
            }

            @media only screen and (min-width : $bp-l) {
                background: $cl-anthrazit-light-00;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                padding: 0;
                display: flex;
                max-height: 20rem;
                margin-bottom: 0;

                li {
                    position: relative;
                    display: inline-block;
                    width: 50%;
                    padding: 2rem 2rem 0 2rem;
                    z-index: 1;

                    &:hover {
                        .menu__navigation__second-menu__title::after {
                            width: 6rem;
                        }

                        .menu__navigation__second-menu__image {
                            transform: rotate(7deg);
                        }
                    }

                    &:first-child {
                        border-right: 1px solid #cacaca;
                        border-left: 1px solid #cacaca;
                        width: calc(50% + 1px);
                    }

                    .menu__navigation__second-menu__title {
                        font-size: $font-size-l;
                        position: relative;
                        margin-bottom: 4rem;
                        display: block;
                        &::after {
                            content: '';
                            background: $cl-anthrazit-dark;
                            height: .1rem;
                            width: 4rem;
                            display: block;
                            bottom: -$spacing-2x;
                            position: absolute;
                            transition: all .1s ease-in;
                        }
                    }
                    .menu__navigation__second-menu__text-image {
                        p {
                            font-size: $font-size-base;
                            width: 75%;
                            display: block;
                        }

                        .menu__navigation__second-menu__image {
                            position: absolute;
                            width: 17%;
                            display: block;
                            right: $spacing-2x;
                            bottom: $spacing-2x;
                            transition: all .1s ease-in;

                            img {
                                background: none;
                            }
                        }
                    }

                    &:last-child {
                        .menu__navigation__second-menu__image {
                            width: 35%;
                            right: -5rem;
                            bottom: 5rem;
                        }
                    }
                }
            }

            @media only screen and (min-width : 1124px) {
                width: 60%;
                max-height: 22rem;
            }
        }
    }
}

.grid {
    background: $cl-anthrazit-light-00;
    height: 100vh;
    overflow-x: scroll;
    position: fixed;
    left: 0;
    width: 100vw;
    top: 6rem;

    @media only screen and (min-width : $bp-l) {
        background: linear-gradient(90deg, #cacaca 1px, #0000 1px) 50%/60%;
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        opacity: 0;
        transition: all 1s ease-in;

        &::after {
            content: '';
            background: linear-gradient(90deg, #cacaca 1px, #0000 1px) 100%/50%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: block;
        }
    }
}

.menu-animation {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    position: fixed;
    &--active {
        z-index: 20;
        .menu-animation__up {
            animation: menu-up 1s;
        }

        .menu-animation__down {
            animation: menu-down 1s;
        }
    }

    &__up {
        background: $cl-anthrazit-dark;
        height: 100vh;
        width: 20%;
        top: -100%;
        position: absolute;
        left: 0;

        &-2 {
            width: 30%;
            left: 50%;
        }
    }

    &__down {
        background: $cl-mid;
        height: 100vh;
        width: 30%;
        bottom: -100%;
        position: absolute;
        left: 20%;

        &-2 {
            width: 20%;
            left: 80%;
        }
    }
}

@keyframes menu-up {
    0% {
        top: -100%;
    }
    70%,
    80% {
        top: 0;
    }
    100% {
        top: 100%;
    }
}

@keyframes menu-down {
    0% {
        bottom: -100%;
    }
    70%,
    80% {
        bottom: 0;
    }
    100% {
        bottom: 100%;
    }
}

