// Color-Variables

// primary colors
$cl-primary-00:     				#d81300;
$cl-primary-00--darken:				#bf0b00;
$cl-primary-00--lighten:			#ff7a72;
$cl-primary-10:     				#fff470;
$cl-primary-10--darken:				#d5c939;
$cl-primary-20:     				#5bbeb8;
$cl-primary-30:						#5b2b53;

$cl-white: #fff;
$cl-black: #000;

// additional colors
// anthrazit

$cl-anthrazit:            #5d5d5d;
$cl-mid:	              #2b2b2b;
$cl-anthrazit-dark:		  #1c1c1c;

$cl-anthrazit-light-00:	  #f5f5f5;
$cl-anthrazit-light-05:	  #ebebeb; //background
$cl-anthrazit-light-10:	  #a7a7a7;

//Shadows
$shadow-00: 1px 1px 16px 0 #cccec9;

//transparency
//$cl-transparency-00: hsla(0, 0%, 96%, 0.6);

// text
//$cl-font: $cl-anthrazit-dark; //basic-text-color

//Darken - Gradients
//$gradient-top-right-to-bottom-left: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);

//Color - Gradients
//$gradient-color-01: linear-gradient(to right top, #881e53, #99124b, #a7013f, #b30030, #bb071c);
