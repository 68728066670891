.release-page {
    .releases {

        .banderole-container {
            margin-top: 15rem;
        }

        &__container {
            display: flex;
            flex-direction: column;

            @media only screen and (min-width : $bp-l) {
                flex-direction: row;
            }
        }

        &__bg {
            background: $cl-anthrazit-light-00;
            padding: 0 2rem;

            @media only screen and (min-width : $bp-l) {
                padding: 0 calc(5% + 1rem);
            }
        }

        &__year {
            display: block;
            transform: translateY(-50%);

            @media only screen and (min-width : $bp-l) {
                font-size: $font-size-xl;
            }
        }

        .glitch-1 {
            @media only screen and (min-width : $bp-l) {
                width: 50%;
                height: 40vw;
                max-height: 40rem;
            }
        }

        .glitch {
            width: 30rem;
            height: 40rem;
        }

        &__cover {
            margin: -20rem auto $spacing-4x;
            z-index: 1;
            position: relative;
            background: $cl-white;
            padding: 2rem;
            height: fit-content;

            @media only screen and (min-width : $bp-m) {
                width: 70%;
            }

            @media only screen and (min-width : $bp-l) {
                margin: 5rem 0 0 -25rem;
            }

            @media only screen and (min-width : $bp-l) {
                width: 30%;
            }

            &__buylinks {
                text-align: center;
                line-height: $line-height-base;
                font-weight: $font-weight-regular;

                span,
                a {
                    display: block;

                    @media only screen and (min-width : 1250px) {
                        display: inline-block;
                    }
                }

                span {
                    margin: $spacing-1x 0;
                    display: block;
                }

                a {
                    color: $cl-primary-00;

                    &:hover {
                        color: $cl-anthrazit-dark;
                    }

                    @media only screen and (min-width : 1250px) {
                        &:nth-child(3) {
                            &::before,
                            &::after {
                                content: '—';
                                margin: 0 1rem;
                            }
                        }
                    }
                }
            }
        }

        &__info-box {
            @media only screen and (min-width : $bp-l) {
                width: 28%;
                margin-left: 3rem;
            }

            &__features__title {
                font-weight: $font-weight-regular;
                display: block;
            }

            &__charts {
                margin-bottom: 2.5rem;
                &__title {
                    font-weight: $font-weight-regular;
                }
            }
        }

        .buy-btn-wrapper {
            margin-right: 10%;
        }
    }
}
