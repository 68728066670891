@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol {
  list-style: decimal; }

ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before, blockquote::after {
  content: none; }

a {
  color: inherit;
  text-decoration: none; }

q::before, q::after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: 0; }

html {
  box-sizing: border-box;
  color: #1c1c1c;
  background: #ebebeb;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  font-weight: 300;
  height: 100%;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth; }

body {
  font-size: 1.8rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  line-height: 1.6;
  position: relative; }

.main,
main {
  padding-bottom: 8rem;
  padding-top: 8rem;
  overflow-x: hidden; }
  @media only screen and (min-width: 900px) {
    .main,
    main {
      padding-bottom: 12rem;
      padding-top: 0; } }

@media only screen and (min-width: 900px) {
  .disable-scrolling {
    overflow: hidden;
    position: fixed; } }

p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 25px;
  margin-top: 0; }

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
  margin-top: 0; }

hr,
.hr {
  border: 1px solid;
  margin: -1px 0; }

a,
b,
i,
strong,
em,
small,
code {
  line-height: 1.4; }

sub,
sup {
  line-height: 1.4;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

h1,
.h1-style {
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 12rem;
  position: relative;
  font-size: 4.8rem; }
  @media only screen and (min-width: 900px) {
    h1,
    .h1-style {
      font-size: 7.2rem;
      margin-bottom: 15rem; } }
  h1::after,
  .h1-style::after {
    content: '';
    background: #1c1c1c;
    height: .1rem;
    width: 10rem;
    display: block;
    bottom: -4rem;
    position: absolute; }

h2,
.h2-style {
  font-size: 5.8rem;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 2.5rem; }

h3,
.h3-style {
  font-size: 2.9rem;
  line-height: 1.6;
  margin-bottom: 2.5rem; }

h4,
.h4-style {
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 2.5rem; }

.container {
  margin: 0 auto;
  min-width: calc(320px - 5%);
  width: 90%; }
  @media only screen and (min-width: 900px) {
    .container {
      padding-right: 10rem; } }

p {
  line-height: 1.6;
  margin-bottom: 2.5rem; }

img {
  background-color: #5d5d5d;
  display: block;
  height: calc(9 * 90vw / 16);
  max-width: 100%;
  object-fit: cover; }

img,
picture {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: top;
  width: 100%; }

.big-list li::after {
  content: '';
  display: block;
  background: #fff;
  height: .1rem;
  width: 5rem;
  margin-top: 2rem; }

.big-list li:last-child {
  margin-bottom: 8rem; }
  .big-list li:last-child::after {
    display: none; }

ol {
  list-style-position: inside; }

.text-link-style,
.md-text a {
  color: #d81300;
  cursor: pointer;
  text-decoration: none; }

.buy-btn-wrapper {
  transform: translateY(50%);
  justify-content: flex-end;
  display: flex; }

.buy-btn {
  display: flex;
  align-items: center;
  font-weight: 400; }
  .buy-btn:hover::before {
    transform: scale(1); }
  .buy-btn:hover::after {
    animation: pulse .4s;
    background-color: #1c1c1c; }
  .buy-btn::before {
    position: absolute;
    content: '';
    height: 7rem;
    width: 7rem;
    display: block;
    background-color: #1c1c1c;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
    border-radius: 100%;
    text-align: center;
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-left: 2rem;
    right: 0;
    transform: scale(0);
    transition: all .3s ease-in-out;
    z-index: 2; }
  .buy-btn::after {
    content: '';
    height: 7rem;
    width: 7rem;
    display: block;
    background-color: #d81300;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
    border-radius: 100%;
    text-align: center;
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-left: 2rem; }

@keyframes pulse {
  0% {
    transform: scale(1); }
  80% {
    transform: scale(1.5); }
  100% {
    opacity: 0; } }

.glitch-1 {
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #f9d77e;
  --color-link-hover: #fff;
  --color-info: #efc453;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --time-anim: 4s;
  --delay-anim: 2s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: #af4949;
  position: relative; }

/* Glitch styles */
.glitch {
  width: var(--glitch-width);
  height: var(--glitch-height);
  overflow: hidden; }

.glitch__img {
  position: absolute;
  top: calc(-1 * var(--gap-vertical));
  left: calc(-1 * var(--gap-horizontal));
  width: calc(100% + var(--gap-horizontal) * 2);
  height: calc(100% + var(--gap-vertical) * 2);
  background-color: var(--blend-color-1);
  background-size: cover;
  transform: translate3d(0, 0, 0);
  background-blend-mode: var(--blend-mode-1); }
  .glitch__img::after {
    content: '';
    background: url("../images/noiz.gif") 50%;
    display: block;
    height: 100%;
    left: 0;
    opacity: .12;
    position: absolute;
    top: 0;
    width: 100%; }

.glitch__img:nth-child(n+2) {
  animation-duration: var(--time-anim);
  animation-delay: var(--delay-anim);
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

.glitch__img:nth-child(2) {
  background-color: var(--blend-color-2);
  background-blend-mode: var(--blend-mode-2);
  animation-name: glitch-anim-1; }

.glitch__img:nth-child(3) {
  background-color: var(--blend-color-3);
  background-blend-mode: var(--blend-mode-3);
  animation-name: glitch-anim-2; }

.glitch__img:nth-child(4) {
  background-color: var(--blend-color-4);
  background-blend-mode: var(--blend-mode-4);
  animation-name: glitch-anim-3; }

.glitch__img:nth-child(5) {
  background-color: var(--blend-color-5);
  background-blend-mode: var(--blend-mode-5);
  animation-name: glitch-anim-flash; }

/* Animations */
@keyframes glitch-anim-1 {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%); }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%); }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%); }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0); }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%); }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%); }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%); }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%); }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%); }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%); }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%); }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%); }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%); }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0); }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%); }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%); }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%); }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%); }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%); }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%); }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%); }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%); }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%); }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%); }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%); }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%); }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%); }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%); }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%); }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1); }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-text {
  0% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  2% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  4% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  7% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  8% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  9% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  9.9% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1); }
  10%,
  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

/* Flash */
@keyframes glitch-anim-flash {
  0%,
  5% {
    opacity: .2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); }
  5.5%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0); } }

.banderole-container {
  position: relative;
  height: 12rem; }
  @media only screen and (min-width: 900px) {
    .banderole-container {
      height: 22rem; } }

.banderole-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  transform: none !important; }

.banderole {
  font-weight: 900;
  text-transform: uppercase;
  color: #d81300;
  font-size: 9.5vw;
  position: absolute;
  bottom: 1rem;
  overflow: hidden;
  text-align: center;
  width: 100%;
  line-height: 1; }
  @media only screen and (min-width: 900px) {
    .banderole {
      white-space: nowrap;
      animation: banderole 45s linear infinite;
      font-size: 17rem;
      top: 0; } }

@keyframes banderole {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(50%); } }

.cookie-notification {
  background: #fff;
  position: fixed;
  max-width: 95%;
  bottom: 1rem;
  left: 1rem;
  z-index: 20;
  padding: 1rem;
  font-size: 1.8rem;
  box-shadow: 1px 1px 16px 0 #cccec9; }
  @media only screen and (min-width: 600px) {
    .cookie-notification {
      max-width: 32rem; } }
  .cookie-notification p {
    width: calc(100% - 4rem); }
  .cookie-notification .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    opacity: .3; }
    .cookie-notification .close:hover {
      opacity: 1; }
    .cookie-notification .close::before, .cookie-notification .close::after {
      position: absolute;
      left: 1rem;
      content: ' ';
      height: 2rem;
      width: .2rem;
      background-color: #333; }
    .cookie-notification .close::before {
      transform: rotate(45deg); }
    .cookie-notification .close::after {
      transform: rotate(-45deg); }
  .cookie-notification__cta-box {
    display: flex;
    flex-direction: column; }
    .cookie-notification__cta-box .btn {
      border: 0;
      padding: 1rem;
      font-size: 1.8rem;
      font-weight: 300;
      color: #1c1c1c;
      margin-bottom: 1rem;
      transition: background .2 linear; }
      .cookie-notification__cta-box .btn:last-child {
        margin-bottom: 0; }
      .cookie-notification__cta-box .btn--primary {
        background: #d81300;
        color: #fff;
        padding: 1rem 7rem;
        width: 100%;
        margin-bottom: 1rem; }
        .cookie-notification__cta-box .btn--primary:hover {
          background: #bf0b00; }
      .cookie-notification__cta-box .btn--secondary {
        font-size: 1.6rem;
        font-weight: 300;
        color: #5d5d5d;
        background: #ebebeb; }
        .cookie-notification__cta-box .btn--secondary:hover {
          background: #a7a7a7; }

.default-page figure img {
  max-width: 90rem;
  margin-bottom: 2rem; }

.default-page strong {
  font-weight: 400; }

.header {
  background: #fff;
  width: 100%;
  z-index: 10;
  height: 6rem;
  position: fixed;
  top: 0; }
  @media only screen and (min-width: 900px) {
    .header {
      position: fixed;
      right: 0;
      height: 100%;
      width: 10rem; }
      .header::after {
        content: '';
        height: 100%;
        width: 10rem;
        background: #fff;
        display: block;
        top: 0;
        position: fixed;
        z-index: 1; } }
  .header__logo {
    display: block;
    width: 10rem;
    height: 6rem;
    padding: 1rem;
    z-index: 2;
    position: relative; }
    .header__logo svg {
      height: 100%;
      width: 100%; }
    @media only screen and (min-width: 900px) {
      .header__logo {
        transform: rotate(90deg);
        margin-top: 4rem;
        transition: .1s ease-in-out; }
        .header__logo:hover {
          transform: rotate(90deg) scale(1.1); } }
  .header .menu-toggle {
    background: #d81300;
    height: 6rem;
    display: block;
    width: 6rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 2; }
    @media only screen and (min-width: 900px) {
      .header .menu-toggle {
        height: 20%;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        transition: all 0.2s cubic-bezier(0.7, 0.06, 0.31, 0.84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms; }
        .header .menu-toggle:hover {
          width: calc(100% + 1rem); }
          .header .menu-toggle:hover .hamburger span:nth-child(2) {
            transform: translateX(-0.7rem); } }
    .header .menu-toggle span {
      transition: .15s ease-in-out;
      display: block;
      background: #fff; }
    .header .menu-toggle .hamburger {
      position: absolute;
      height: 2rem;
      width: 3rem;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }
      @media only screen and (min-width: 900px) {
        .header .menu-toggle .hamburger {
          height: 2.7rem;
          width: 4rem; } }
      .header .menu-toggle .hamburger span {
        transition: .25s ease-in-out;
        width: 100%;
        position: relative;
        height: .2rem; }
        .header .menu-toggle .hamburger span:nth-child(1) {
          transition-delay: .4s; }
        .header .menu-toggle .hamburger span:nth-child(2) {
          transition: width 0.25s ease-in-out 0.525s, transform 0.2s cubic-bezier(0.7, 0.06, 0.31, 0.84);
          margin: .6rem 0; }
          @media only screen and (min-width: 900px) {
            .header .menu-toggle .hamburger span:nth-child(2) {
              margin: 1rem 0; } }
        .header .menu-toggle .hamburger span:nth-child(3) {
          transition-delay: .65s; }
    .header .menu-toggle .cross {
      position: absolute;
      height: 4rem;
      width: 4rem;
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      left: 50%; }
      .header .menu-toggle .cross span:nth-child(1) {
        height: 0%;
        width: .2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition-delay: 0s; }
      .header .menu-toggle .cross span:nth-child(2) {
        width: 0%;
        height: .2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition-delay: .25s; }
    .header .menu-toggle--is-open .hamburger span {
      width: 0%; }
      .header .menu-toggle--is-open .hamburger span:nth-child(1) {
        transition-delay: 0s; }
      .header .menu-toggle--is-open .hamburger span:nth-child(2) {
        transition-delay: .125s; }
      .header .menu-toggle--is-open .hamburger span:nth-child(3) {
        transition-delay: .25s; }
    .header .menu-toggle--is-open .cross span:nth-child(1) {
      height: 80%;
      transition-delay: .625s; }
    .header .menu-toggle--is-open .cross span:nth-child(2) {
      width: 80%;
      transition-delay: .375s; }
  .header__shop-cta {
    bottom: 1rem;
    position: absolute;
    text-align: center;
    width: 70%;
    background: #5d5d5d;
    margin: 0 15%;
    line-height: 4rem;
    display: none;
    visibility: none;
    color: #fff; }
    @media only screen and (min-width: 900px) {
      .header__shop-cta {
        z-index: 2;
        display: block;
        visibility: visible;
        transition: .1s ease-in-out; }
        .header__shop-cta:hover {
          background: #a7a7a7;
          transform: scale(1.1); } }

.header.is-hidden {
  transform: translateY(-100%); }

.menu {
  z-index: -1;
  opacity: 0;
  left: 100%;
  transition: all 0.2s cubic-bezier(0.7, 0.06, 0.31, 0.84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  overflow-y: scroll;
  height: 100vh;
  visibility: hidden; }
  .menu .signature {
    display: none;
    visibility: hidden; }
  @media only screen and (min-width: 900px) {
    .menu {
      overflow-y: unset; }
      .menu .signature {
        position: absolute;
        left: 2rem;
        top: 11rem;
        z-index: 2;
        font-weight: 400;
        transform: translate(-50%, 0) rotate(90deg); } }
  .menu__title {
    font-size: 3.6rem;
    margin-bottom: 4rem;
    padding-left: 5%;
    z-index: 1; }
    .menu__title::after {
      bottom: -2rem; }
    @media only screen and (min-width: 900px) {
      .menu__title {
        font-size: 7.2rem;
        display: block;
        padding-left: calc(50% + 2rem);
        margin-bottom: 5vh; } }
  .menu--is-open {
    background: #a7a7a7;
    position: fixed;
    top: 6rem;
    left: 0;
    opacity: 1;
    width: 100%;
    display: block;
    visibility: visible; }
    @media only screen and (min-width: 900px) {
      .menu--is-open {
        background: url("../images/herzog-menu.jpg") 55% 50% no-repeat;
        width: calc(100% - 10rem);
        height: 100%;
        top: 0;
        background-size: cover; }
        .menu--is-open::after {
          content: '';
          background: url("../images/noiz.gif") 50%;
          display: block;
          height: 100%;
          left: 0;
          opacity: .06;
          position: absolute;
          top: 0;
          width: 100%; }
        .menu--is-open .grid {
          opacity: 1;
          top: 0; } }
  .menu__navigation {
    font-size: 2.4rem;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 900px) {
      .menu__navigation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 38rem);
        font-size: 2.6rem; } }
    .menu__navigation .menu-link {
      position: relative;
      z-index: 1;
      width: fit-content; }
      .menu__navigation .menu-link::after {
        content: '';
        transition: all 0.2s cubic-bezier(0.7, 0.06, 0.31, 0.84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
        width: 0; }
      @media only screen and (min-width: 900px) {
        .menu__navigation .menu-link:hover::after {
          content: '';
          background: #ff7a72;
          display: block;
          width: 105%;
          height: 35%;
          position: absolute;
          bottom: 0;
          z-index: -1;
          left: -2.5%; } }
      .menu__navigation .menu-link--is-active::after {
        content: '';
        background: #ff7a72;
        display: block;
        width: 105%;
        height: 35%;
        position: absolute;
        bottom: 0;
        z-index: -1;
        left: -2.5%; }
    .menu__navigation__first-menu, .menu__navigation__meta-menu, .menu__navigation__second-menu {
      position: relative;
      z-index: 2; }
    .menu__navigation__first-menu {
      padding: 0 5%;
      margin-bottom: 1rem; }
      @media only screen and (min-width: 900px) {
        .menu__navigation__first-menu {
          padding-left: calc(50% + 2rem);
          margin-bottom: 0; } }
    .menu__navigation__meta-menu {
      font-size: 1.4rem;
      display: flex;
      padding: 2rem 5%;
      width: 100%;
      align-self: flex-end;
      margin-top: auto; }
      @media only screen and (min-width: 900px) {
        .menu__navigation__meta-menu {
          font-size: 1.8rem;
          padding-left: calc(50% + 2rem);
          padding-bottom: 0;
          margin-top: 0;
          height: 100%; }
          .menu__navigation__meta-menu ul {
            margin-bottom: .5vh;
            display: flex;
            flex-direction: column;
            align-self: flex-end; } }
      .menu__navigation__meta-menu__social {
        margin-right: 15%; }
    .menu__navigation__second-menu {
      padding: 1rem 5% 1rem 5%;
      margin-bottom: 10%;
      background: #fff; }
      .menu__navigation__second-menu li p {
        display: none; }
      .menu__navigation__second-menu li .menu__navigation__second-menu__image {
        display: none; }
      @media only screen and (min-width: 900px) {
        .menu__navigation__second-menu {
          background: #f5f5f5;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          padding: 0;
          display: flex;
          max-height: 20rem;
          margin-bottom: 0; }
          .menu__navigation__second-menu li {
            position: relative;
            display: inline-block;
            width: 50%;
            padding: 2rem 2rem 0 2rem;
            z-index: 1; }
            .menu__navigation__second-menu li:hover .menu__navigation__second-menu__title::after {
              width: 6rem; }
            .menu__navigation__second-menu li:hover .menu__navigation__second-menu__image {
              transform: rotate(7deg); }
            .menu__navigation__second-menu li:first-child {
              border-right: 1px solid #cacaca;
              border-left: 1px solid #cacaca;
              width: calc(50% + 1px); }
            .menu__navigation__second-menu li .menu__navigation__second-menu__title {
              font-size: 3.6rem;
              position: relative;
              margin-bottom: 4rem;
              display: block; }
              .menu__navigation__second-menu li .menu__navigation__second-menu__title::after {
                content: '';
                background: #1c1c1c;
                height: .1rem;
                width: 4rem;
                display: block;
                bottom: -2rem;
                position: absolute;
                transition: all .1s ease-in; }
            .menu__navigation__second-menu li .menu__navigation__second-menu__text-image p {
              font-size: 1.8rem;
              width: 75%;
              display: block; }
            .menu__navigation__second-menu li .menu__navigation__second-menu__text-image .menu__navigation__second-menu__image {
              position: absolute;
              width: 17%;
              display: block;
              right: 2rem;
              bottom: 2rem;
              transition: all .1s ease-in; }
              .menu__navigation__second-menu li .menu__navigation__second-menu__text-image .menu__navigation__second-menu__image img {
                background: none; }
            .menu__navigation__second-menu li:last-child .menu__navigation__second-menu__image {
              width: 35%;
              right: -5rem;
              bottom: 5rem; } }
      @media only screen and (min-width: 1124px) {
        .menu__navigation__second-menu {
          width: 60%;
          max-height: 22rem; } }

.grid {
  background: #f5f5f5;
  height: 100vh;
  overflow-x: scroll;
  position: fixed;
  left: 0;
  width: 100vw;
  top: 6rem; }
  @media only screen and (min-width: 900px) {
    .grid {
      background: linear-gradient(90deg, #cacaca 1px, #0000 1px) 50%/60%;
      position: absolute;
      top: 2rem;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: block;
      opacity: 0;
      transition: all 1s ease-in; }
      .grid::after {
        content: '';
        background: linear-gradient(90deg, #cacaca 1px, #0000 1px) 100%/50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block; } }

.menu-animation {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  position: fixed; }
  .menu-animation--active {
    z-index: 20; }
    .menu-animation--active .menu-animation__up {
      animation: menu-up 1s; }
    .menu-animation--active .menu-animation__down {
      animation: menu-down 1s; }
  .menu-animation__up {
    background: #1c1c1c;
    height: 100vh;
    width: 20%;
    top: -100%;
    position: absolute;
    left: 0; }
    .menu-animation__up-2 {
      width: 30%;
      left: 50%; }
  .menu-animation__down {
    background: #2b2b2b;
    height: 100vh;
    width: 30%;
    bottom: -100%;
    position: absolute;
    left: 20%; }
    .menu-animation__down-2 {
      width: 20%;
      left: 80%; }

@keyframes menu-up {
  0% {
    top: -100%; }
  70%,
  80% {
    top: 0; }
  100% {
    top: 100%; } }

@keyframes menu-down {
  0% {
    bottom: -100%; }
  70%,
  80% {
    bottom: 0; }
  100% {
    bottom: 100%; } }

.tour .glitch {
  position: fixed;
  top: 0;
  left: 0; }
  .tour .glitch div::after {
    opacity: .1; }

.tour .container {
  position: relative;
  z-index: 1; }

.tour__title {
  color: #fff; }
  .tour__title::after {
    background-color: #fff; }

.tour__text-top {
  width: 80%;
  color: #fff;
  font-size: 2.4rem;
  margin-bottom: 6rem; }
  .tour__text-top a {
    box-shadow: 0 3px 0 #fff;
    cursor: pointer; }
  @media only screen and (min-width: 900px) {
    .tour__text-top {
      font-size: 4.8rem; } }

.tour__list {
  overflow: hidden;
  margin-bottom: 0; }
  .tour__list__event {
    color: #fff;
    font-size: 3.6rem;
    font-weight: 300;
    margin-bottom: 6rem; }
    .tour__list__event__date {
      display: block;
      font-size: 2.4rem; }
    @media only screen and (min-width: 900px) {
      .tour__list__event__title {
        margin-left: 2rem;
        font-size: 5.8rem; } }

.tour__additional-infos {
  margin-top: 8rem;
  width: 32rem;
  color: #fff;
  line-height: 1.6; }

@media only screen and (min-width: 900px) {
  .video-page__title {
    color: #fff; }
    .video-page__title::after {
      background-color: #fff; } }

.video-page__list {
  counter-reset: section; }
  .video-page__list li {
    color: #fff;
    font-size: 2.4rem;
    margin-bottom: 1rem;
    position: relative; }
    .video-page__list li .video-list__item__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 400;
      width: fit-content;
      text-align: center;
      background-image: linear-gradient(to right, #d81300 0%, #d81300 100%);
      background-repeat: repeat-x;
      background-position: 0 90%;
      background-size: 100% 35%; }
    @media only screen and (min-width: 900px) {
      .video-page__list li {
        font-size: 5.8rem; }
        .video-page__list li::before {
          counter-increment: section;
          content: counter(section) "—"; }
        .video-page__list li:nth-child(1)::before, .video-page__list li:nth-child(2)::before, .video-page__list li:nth-child(3)::before, .video-page__list li:nth-child(4)::before, .video-page__list li:nth-child(5)::before, .video-page__list li:nth-child(6)::before, .video-page__list li:nth-child(7)::before, .video-page__list li:nth-child(8)::before, .video-page__list li:nth-child(9)::before {
          content: "0" counter(section) " — "; }
        .video-page__list li .video-list__item__title {
          text-align: left;
          font-weight: 300;
          position: relative;
          left: 0;
          background-size: 100% 0%;
          transition: all .2s ease-in-out; }
          .video-page__list li .video-list__item__title:hover {
            background-image: linear-gradient(to right, #d81300 0%, #d81300 100%);
            background-repeat: repeat-x;
            background-position: 0 90%;
            background-size: 100% 35%; }
        .video-page__list li .video-list__item__gif {
          display: none; } }

.video-page__background {
  display: none;
  z-index: -1;
  background: #d81300;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 6rem;
  margin-bottom: 0; }
  @media only screen and (min-width: 900px) {
    .video-page__background {
      top: 0;
      display: block; } }
  .video-page__background__img {
    opacity: 0;
    visibility: hidden;
    transition: all .2s linear; }
    .video-page__background__img--active {
      opacity: .4;
      visibility: visible;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      filter: grayscale(100%); }
      @media only screen and (min-width: 900px) {
        .video-page__background__img--active {
          width: calc(100% - 10rem); } }
      .video-page__background__img--active::after {
        content: '';
        background: url("../images/noiz.gif") 50%;
        display: block;
        height: 100%;
        left: 0;
        opacity: .15;
        position: absolute;
        top: 0;
        width: 100%; }
    .video-page__background__img img {
      position: absolute;
      top: 0;
      left: 0; }

.release-page .releases .banderole-container {
  margin-top: 15rem; }

.release-page .releases__container {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 900px) {
    .release-page .releases__container {
      flex-direction: row; } }

.release-page .releases__bg {
  background: #f5f5f5;
  padding: 0 2rem; }
  @media only screen and (min-width: 900px) {
    .release-page .releases__bg {
      padding: 0 calc(5% + 1rem); } }

.release-page .releases__year {
  display: block;
  transform: translateY(-50%); }
  @media only screen and (min-width: 900px) {
    .release-page .releases__year {
      font-size: 4.8rem; } }

@media only screen and (min-width: 900px) {
  .release-page .releases .glitch-1 {
    width: 50%;
    height: 40vw;
    max-height: 40rem; } }

.release-page .releases .glitch {
  width: 30rem;
  height: 40rem; }

.release-page .releases__cover {
  margin: -20rem auto 4rem;
  z-index: 1;
  position: relative;
  background: #fff;
  padding: 2rem;
  height: fit-content; }
  @media only screen and (min-width: 600px) {
    .release-page .releases__cover {
      width: 70%; } }
  @media only screen and (min-width: 900px) {
    .release-page .releases__cover {
      margin: 5rem 0 0 -25rem; } }
  @media only screen and (min-width: 900px) {
    .release-page .releases__cover {
      width: 30%; } }
  .release-page .releases__cover__buylinks {
    text-align: center;
    line-height: 1.6;
    font-weight: 400; }
    .release-page .releases__cover__buylinks span,
    .release-page .releases__cover__buylinks a {
      display: block; }
      @media only screen and (min-width: 1250px) {
        .release-page .releases__cover__buylinks span,
        .release-page .releases__cover__buylinks a {
          display: inline-block; } }
    .release-page .releases__cover__buylinks span {
      margin: 1rem 0;
      display: block; }
    .release-page .releases__cover__buylinks a {
      color: #d81300; }
      .release-page .releases__cover__buylinks a:hover {
        color: #1c1c1c; }
      @media only screen and (min-width: 1250px) {
        .release-page .releases__cover__buylinks a:nth-child(3)::before, .release-page .releases__cover__buylinks a:nth-child(3)::after {
          content: '—';
          margin: 0 1rem; } }

@media only screen and (min-width: 900px) {
  .release-page .releases__info-box {
    width: 28%;
    margin-left: 3rem; } }

.release-page .releases__info-box__features__title {
  font-weight: 400;
  display: block; }

.release-page .releases__info-box__charts {
  margin-bottom: 2.5rem; }
  .release-page .releases__info-box__charts__title {
    font-weight: 400; }

.release-page .releases .buy-btn-wrapper {
  margin-right: 10%; }

.publishing-page {
  background: #ebebeb;
  overflow: hidden; }
  .publishing-page .text-img-combi {
    display: flex;
    align-items: center;
    flex-direction: column; }
    @media only screen and (min-width: 900px) {
      .publishing-page .text-img-combi {
        flex-direction: row; }
        .publishing-page .text-img-combi__text {
          width: 50%;
          margin-right: 10%; }
        .publishing-page .text-img-combi__img {
          width: 50%; } }
  .publishing-page .publishing-slider-wrapper {
    position: relative; }
    .publishing-page .publishing-slider-wrapper .publishing-slider-prev,
    .publishing-page .publishing-slider-wrapper .publishing-slider-next {
      background: #1c1c1c;
      position: absolute;
      height: 4rem;
      width: 4rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1; }
      @media only screen and (min-width: 900px) {
        .publishing-page .publishing-slider-wrapper .publishing-slider-prev,
        .publishing-page .publishing-slider-wrapper .publishing-slider-next {
          height: 7rem;
          width: 7rem; } }
      .publishing-page .publishing-slider-wrapper .publishing-slider-prev:hover,
      .publishing-page .publishing-slider-wrapper .publishing-slider-next:hover {
        background: #5d5d5d;
        transition: background .1s linear; }
      .publishing-page .publishing-slider-wrapper .publishing-slider-prev::after,
      .publishing-page .publishing-slider-wrapper .publishing-slider-next::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        border: solid #fff;
        border-width: 0 .1rem .1rem 0;
        display: inline-block;
        padding: .5rem;
        transform: translate(50%, -70%) rotate(135deg); }
    .publishing-page .publishing-slider-wrapper .publishing-slider-next {
      right: 0; }
      @media only screen and (min-width: 900px) {
        .publishing-page .publishing-slider-wrapper .publishing-slider-next {
          right: 10rem; } }
      .publishing-page .publishing-slider-wrapper .publishing-slider-next::after {
        transform: translate(50%, -70%) rotate(-45deg); }
  .publishing-page .artists {
    position: relative; }
    @media only screen and (min-width: 900px) {
      .publishing-page .artists {
        padding-right: 10rem; } }
    .publishing-page .artists .slick-track {
      padding: 2rem 0; }
    .publishing-page .artists::before {
      content: '';
      display: block;
      background: #d81300;
      height: calc(100% - 20rem);
      position: absolute;
      top: 10rem;
      width: 100%; }
    .publishing-page .artists__item {
      display: inline-block;
      position: relative;
      margin: 0 4rem;
      background: #fff;
      padding: 2rem 2rem 1rem 2rem;
      z-index: 2; }
      .publishing-page .artists__item__top-line {
        position: absolute;
        top: 0;
        transform: translateY(-55%);
        display: none; }
        @media only screen and (min-width: 900px) {
          .publishing-page .artists__item__top-line {
            display: block;
            font-size: 2.4rem; } }
      .publishing-page .artists__item__title {
        position: absolute;
        color: #fff;
        font-size: 4.8rem;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, -25%);
        text-align: center;
        line-height: 1.6; }
        .publishing-page .artists__item__title span {
          background-image: linear-gradient(to right, #d81300 0%, #d81300 100%);
          background-repeat: repeat-x;
          background-position: 0 100%;
          background-size: 100% 25%; }
      .publishing-page .artists__item .social-links {
        margin-top: 1rem;
        text-align: center;
        color: #d81300;
        font-weight: 400; }
        .publishing-page .artists__item .social-links__item {
          font-size: 1.8rem;
          display: block; }
          .publishing-page .artists__item .social-links__item:hover {
            color: #1c1c1c; }
          @media only screen and (min-width: 600px) {
            .publishing-page .artists__item .social-links__item {
              display: inline-block; }
              .publishing-page .artists__item .social-links__item:nth-child(2)::before, .publishing-page .artists__item .social-links__item:nth-child(2)::after {
                content: '—';
                margin: 0 1rem; } }

.exotic-seed {
  background: #ebebeb;
  overflow: hidden; }
  .exotic-seed .text-img-combi__img img {
    background-color: transparent; }
  @media only screen and (min-width: 900px) {
    .exotic-seed .text-img-combi {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-direction: row; }
      .exotic-seed .text-img-combi__text {
        width: 50%;
        margin-right: 10%; }
      .exotic-seed .text-img-combi__img {
        width: 50%; } }
  .exotic-seed .text-img-combi--intro {
    align-items: end; }
    .exotic-seed .text-img-combi--intro .text-img-combi__img {
      margin-top: -6rem;
      margin-bottom: -15rem;
      padding: 0 10%; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--intro .text-img-combi__img {
          padding: 0; } }
    .exotic-seed .text-img-combi--intro .buy-btn-wrapper {
      margin-bottom: 10rem; }
  .exotic-seed .text-img-combi--v2 {
    margin-bottom: 0; }
    .exotic-seed .text-img-combi--v2 .text-img-combi__img-title {
      width: 100%;
      color: #fff;
      display: inline-block;
      margin-bottom: 1rem; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--v2 .text-img-combi__img-title {
          font-size: 2.4rem; } }
    .exotic-seed .text-img-combi--v2 .text-img-combi__img-container .text-img-combi__img {
      margin-left: calc(-1rem + -5%);
      float: left;
      width: 100%; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--v2 .text-img-combi__img-container .text-img-combi__img {
          margin-left: 0; } }
    .exotic-seed .text-img-combi--v2 .text-img-combi__text {
      background: #fff;
      padding: 2rem;
      margin-right: calc(-1rem + -5%);
      float: right;
      margin-top: -15vw;
      width: 100%;
      z-index: 1; }
      @media only screen and (min-width: 600px) {
        .exotic-seed .text-img-combi--v2 .text-img-combi__text {
          width: 80%;
          padding: 4rem;
          margin-top: -20vw; } }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--v2 .text-img-combi__text {
          margin: 0;
          margin-left: -15rem;
          max-width: 50rem; } }
  .exotic-seed .text-img-combi--v3 {
    margin-bottom: 0;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 900px) {
      .exotic-seed .text-img-combi--v3 {
        flex-direction: row; } }
    .exotic-seed .text-img-combi--v3 .text-img-combi__text {
      order: 2;
      color: #fff; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--v3 .text-img-combi__text {
          order: 1;
          width: 100%; } }
      @media only screen and (min-width: 1024px) {
        .exotic-seed .text-img-combi--v3 .text-img-combi__text {
          width: 50%; } }
    .exotic-seed .text-img-combi--v3 .text-img-combi__img-container {
      order: 1;
      position: relative;
      margin-bottom: 2rem; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .text-img-combi--v3 .text-img-combi__img-container {
          order: 2; } }
      @media only screen and (min-width: 1200px) {
        .exotic-seed .text-img-combi--v3 .text-img-combi__img-container {
          margin-bottom: -10rem; } }
      .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one {
        transform-origin: 0%;
        transform: scale(0.5) translateX(-50%);
        position: absolute;
        bottom: 12%;
        left: 50%;
        color: #fff;
        font-weight: 600;
        width: 160%;
        text-align: center; }
        @media only screen and (min-width: 600px) {
          .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one {
            transform: scale(0.7) translateX(-50%); } }
        @media only screen and (min-width: 900px) {
          .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one {
            transform: scale(0.6) translateX(-50%);
            width: 200%; } }
        @media only screen and (min-width: 1024px) {
          .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one {
            transform: scale(0.8) translateX(-50%);
            width: 100%; } }
        .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one span {
          font-size: 7.2rem;
          display: block;
          width: fit-content;
          position: relative;
          margin: 0 auto; }
          .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img-container__nr-one span::after {
            content: '';
            transition: .1s ease-in-out;
            display: block;
            height: 25%;
            width: 100%;
            background: #d81300;
            position: absolute;
            bottom: .5rem;
            margin-top: 0;
            z-index: -1; }
      .exotic-seed .text-img-combi--v3 .text-img-combi__img-container .text-img-combi__img {
        width: 100%; }
  .exotic-seed__content {
    background: #d81300;
    padding-top: 16rem;
    padding-bottom: 4rem;
    margin-bottom: 8rem; }
    .exotic-seed__content .banderole-container {
      clear: both;
      margin-top: 4rem; }
      @media only screen and (min-width: 900px) {
        .exotic-seed__content .banderole-container {
          margin-top: 8rem; } }
      .exotic-seed__content .banderole-container .banderole {
        color: #ffffff40; }
    @media only screen and (min-width: 900px) {
      .exotic-seed__content {
        padding-top: 20rem;
        padding-bottom: 20rem;
        margin-bottom: 24rem; } }
    @media only screen and (min-width: 1200px) {
      .exotic-seed__content {
        padding-bottom: 0; } }
  .exotic-seed .test-video {
    position: relative;
    display: block;
    cursor: pointer; }
    .exotic-seed .test-video:hover svg {
      fill: #fff;
      background: #d81300; }
    .exotic-seed .test-video svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 14vw;
      height: 14vw;
      padding: 5vw;
      fill: #d81300;
      transition: all .2s linear; }
      @media only screen and (min-width: 900px) {
        .exotic-seed .test-video svg {
          width: 10vw;
          height: 10vw;
          padding: 3.5vw; } }

.roor-page {
  overflow: hidden; }
  .roor-page .text-img-combi {
    margin-bottom: -10rem; }
    .roor-page .text-img-combi__img {
      background-color: transparent;
      padding: 5% 10% 0; }
    @media only screen and (min-width: 900px) {
      .roor-page .text-img-combi {
        display: flex;
        flex-direction: column;
        flex-direction: row; }
        .roor-page .text-img-combi__text {
          width: 50%;
          margin-right: 10%; }
        .roor-page .text-img-combi__img {
          width: 50%;
          margin-top: -25rem;
          padding: 0; } }
  @media only screen and (min-width: 900px) {
    .roor-page__articles {
      margin-top: 25rem; } }
  .roor-page__articles__item {
    margin: 10rem 0; }
    @media only screen and (min-width: 900px) {
      .roor-page__articles__item {
        margin: 0;
        height: 100vh;
        max-height: 900px; } }
    .roor-page__articles__item__title-top {
      font-size: 2.4rem;
      display: inline-block;
      transform: translateY(-55%);
      opacity: 0; }
      @media only screen and (min-width: 900px) {
        .roor-page__articles__item__title-top {
          opacity: 1; } }
    .roor-page__articles__item__description {
      margin-bottom: 4rem; }
      @media only screen and (min-width: 900px) {
        .roor-page__articles__item__description {
          order: 1;
          width: 30%; } }
    @media only screen and (min-width: 900px) {
      .roor-page__articles__item__details {
        order: 3;
        width: 25%; } }
    .roor-page__articles__item__details ul {
      margin-bottom: 4rem; }
    .roor-page__articles__item__details__price {
      font-size: 3.6rem;
      margin-bottom: 0; }
    .roor-page__articles__item__bg {
      background: #f5f5f5; }
    @media only screen and (min-width: 900px) {
      .roor-page__articles__item__flex-container {
        display: flex;
        flex-direction: row;
        align-items: center; } }
    .roor-page__articles__item__image {
      padding: 0 35%;
      margin: -30% 0 4rem 0;
      transform: rotate(0deg);
      opacity: .9; }
      .roor-page__articles__item__image img {
        background: none; }
      @media only screen and (min-width: 900px) {
        .roor-page__articles__item__image {
          width: 20%;
          order: 2;
          padding: 0;
          margin: -15% 5% -15% 20%;
          transform: translate(-50%, 0) rotate(0deg);
          left: 50%;
          top: 50%;
          z-index: 1; } }
      @media only screen and (min-width: 1024px) {
        .roor-page__articles__item__image {
          width: 15%; } }
    @media only screen and (min-width: 900px) {
      .roor-page__articles__item:nth-child(2) .roor-page__articles__item__image {
        transform: translate(-50%, 0) rotate(0deg); }
      .roor-page__articles__item:nth-child(3) .roor-page__articles__item__image {
        transform: translate(-50%, 0) rotate(-5deg); }
      .roor-page__articles__item:nth-child(4) .roor-page__articles__item__image {
        transform: translate(-50%, 0) rotate(-10deg); } }

@media only screen and (min-width: 600px) {
  .default-page {
    width: 80%; } }

.default-page .md-text iframe {
  max-width: 100%; }

.home .intro {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 900px) {
    .home .intro {
      flex-direction: row;
      margin-right: 10rem; } }
  .home .intro__text {
    width: 100%;
    padding: 0 5%;
    order: 2;
    position: relative; }
    @media only screen and (min-width: 900px) {
      .home .intro__text {
        order: 2;
        width: 100%;
        padding: 5rem 10% 10rem 5%;
        background: #fff; } }
    .home .intro__text .scroll-down {
      height: 7rem;
      width: 7rem;
      display: none;
      background-color: #d81300;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.2 477.2' style='enable-background:new 0 0 477.2 477.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1L332,238.6L116.5,454.1 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
      border-radius: 100%;
      text-align: center;
      background-size: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      right: -3.5rem;
      transform: rotate(90deg) translateX(-50%);
      top: 50%;
      position: absolute;
      transition: background-color .2s linear;
      box-shadow: 4px 0 12px 0 #d81301d4; }
      .home .intro__text .scroll-down:hover {
        background-color: #bf0b00; }
      @media only screen and (min-width: 900px) {
        .home .intro__text .scroll-down {
          display: block; } }
  .home .intro__img {
    width: 90%;
    margin: 0 5%;
    order: 1; }
    @media only screen and (min-width: 900px) {
      .home .intro__img {
        order: 2;
        width: 120%;
        margin: 0; } }
    .home .intro__img div {
      opacity: 1;
      background: none; }
      @media only screen and (min-width: 900px) {
        .home .intro__img div {
          opacity: 1;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%; } }
    .home .intro__img img {
      width: 100%; }
      @media only screen and (min-width: 900px) {
        .home .intro__img img {
          opacity: 0; } }

.home .news-feed ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0; }

.home .news-feed .buy-btn-wrapper {
  margin-bottom: 4rem; }

.home .news-feed__item {
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  width: 100%; }
  @media only screen and (min-width: 600px) {
    .home .news-feed__item {
      width: 48.5%; } }
  .home .news-feed__item a {
    padding: 2rem;
    display: block;
    height: 100%; }
  .home .news-feed__item img {
    margin-bottom: 2rem; }
  .home .news-feed__item:nth-child(even) {
    margin-left: 1.25%; }
  .home .news-feed__item:nth-child(odd) {
    margin-right: 1.25%; }
  .home .news-feed__item h3::after {
    content: '';
    height: .1rem;
    width: 5rem;
    background: #1c1c1c;
    display: block;
    margin-top: 2rem; }
  .home .news-feed__item__read-more {
    position: absolute;
    bottom: -1rem;
    right: 2rem; }
    .home .news-feed__item__read-more::before {
      content: '';
      height: .1rem;
      width: 100%;
      background: #1c1c1c;
      display: block;
      position: absolute;
      bottom: 0; }
    .home .news-feed__item__read-more::after {
      content: '';
      border: solid #1c1c1c;
      border-width: 0 .1rem .1rem 0;
      display: inline-block;
      padding: 3px;
      margin: 0 0 .2rem 1rem;
      transform: rotate(-45deg); }
    .home .news-feed__item__read-more:hover {
      color: #d81300; }
      .home .news-feed__item__read-more:hover::before {
        background: #d81300; }
      .home .news-feed__item__read-more:hover::after {
        border-color: #d81300; }

.home .instagram-feed ul {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 600px) {
    .home .instagram-feed ul {
      flex-direction: row; } }
  .home .instagram-feed ul li {
    width: 100%;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 600px) {
      .home .instagram-feed ul li {
        width: 30%; }
        .home .instagram-feed ul li:nth-child(2) {
          margin: 0 5%; } }
    .home .instagram-feed ul li a {
      display: block;
      cursor: pointer; }

.home .intro-slider {
  padding-top: 2rem;
  margin: 15rem 0; }
  .home .intro-slider .slick-list {
    padding-bottom: 4rem; }
  @media only screen and (min-width: 1200px) {
    .home .intro-slider__image {
      width: 70%; } }
  @media only screen and (min-width: 900px) {
    .home .intro-slider__btns {
      position: absolute;
      right: 0;
      bottom: -7rem;
      display: flex; } }
  .home .intro-slider__btns span {
    background: #1c1c1c;
    position: absolute;
    top: -4rem;
    height: 4rem;
    width: 4rem; }
    @media only screen and (min-width: 900px) {
      .home .intro-slider__btns span {
        height: 7rem;
        width: 7rem;
        top: 0;
        display: block;
        position: relative; } }
    .home .intro-slider__btns span:hover {
      background: #5d5d5d;
      transition: background .1s linear; }
    .home .intro-slider__btns span:first-child {
      left: 0; }
    .home .intro-slider__btns span:first-child, .home .intro-slider__btns span:last-child {
      margin-right: .1rem; }
      .home .intro-slider__btns span:first-child::after, .home .intro-slider__btns span:last-child::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        border: solid #fff;
        border-width: 0 .1rem .1rem 0;
        display: inline-block;
        padding: .5rem;
        transform: translate(50%, -70%) rotate(135deg); }
    .home .intro-slider__btns span:last-child {
      right: 0; }
      .home .intro-slider__btns span:last-child::after {
        transform: translate(50%, -70%) rotate(-45deg); }
  .home .intro-slider__content-box {
    z-index: 2;
    background: #fff;
    padding: 2rem;
    position: relative; }
    @media only screen and (min-width: 1200px) {
      .home .intro-slider__content-box {
        width: 40%;
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translateY(-50%); } }
    .home .intro-slider__content-box h3::after {
      content: '';
      height: .1rem;
      width: 5rem;
      background: #1c1c1c;
      display: block;
      margin-top: 2rem; }

.footer {
  background: #5d5d5d;
  color: #fff;
  position: relative;
  padding-top: 4rem; }
  @media only screen and (min-width: 900px) {
    .footer {
      padding-top: 6rem; } }
  .footer .bp-logo {
    fill: #fff;
    width: 10rem;
    margin: 0 auto;
    display: block;
    margin-bottom: 4rem; }
    @media only screen and (min-width: 900px) {
      .footer .bp-logo {
        position: absolute;
        left: calc(50% - 10rem);
        transition: transform .2s ease-in-out; }
        .footer .bp-logo:hover {
          transform: scale(1.1); } }
  @media only screen and (min-width: 900px) {
    .footer__nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  .footer .footer__nav {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 2rem;
    order: 1; }
    .footer .footer__nav a {
      display: block; }
    @media only screen and (min-width: 900px) {
      .footer .footer__nav a {
        text-align: left;
        margin-bottom: 1rem;
        position: relative;
        z-index: 1;
        width: fit-content; }
        .footer .footer__nav a::after {
          content: '';
          transition: all 0.2s cubic-bezier(0.7, 0.06, 0.31, 0.84) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
          width: 0; }
        .footer .footer__nav a:hover::after {
          content: '';
          background: #1c1c1c;
          display: block;
          width: 105%;
          height: 35%;
          position: absolute;
          bottom: 0;
          z-index: -1;
          left: -2.5%; } }
  .footer__icon-list {
    display: flex;
    flex-direction: row;
    padding: 0 5%;
    justify-content: center;
    order: 2; }
    @media only screen and (min-width: 900px) {
      .footer__icon-list {
        padding: 0; } }
    .footer__icon-list li {
      border: 1px solid #fff;
      margin-top: 2rem;
      width: 6rem;
      height: 6rem; }
      .footer__icon-list li:hover svg {
        padding: 1.35rem; }
      .footer__icon-list li:nth-child(2) {
        margin: 2rem 1rem; }
      @media only screen and (min-width: 900px) {
        .footer__icon-list li {
          width: 6rem;
          height: 6rem; } }
      .footer__icon-list li svg {
        fill: #fff;
        padding: 1.5rem;
        width: 100%;
        height: 100%;
        transition: padding .1s ease-in-out; }
  .footer__bottom {
    background: #1c1c1c;
    padding: 1rem 0; }
    .footer__bottom .container {
      display: flex;
      flex-direction: column; }
      @media only screen and (min-width: 900px) {
        .footer__bottom .container {
          flex-direction: row;
          justify-content: space-between; } }
    @media only screen and (min-width: 900px) {
      .footer__bottom {
        margin-top: 3rem; } }
    .footer__bottom span,
    .footer__bottom a {
      text-align: center;
      margin: 1rem 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/node_modules/slick-carousel/slick-carousel/slick/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/node_modules/slick-carousel/slick/fonts/slick.eot");
  src: url("/node_modules/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/node_modules/slick-carousel/slick/fonts/slick.woff") format("woff"), url("/node_modules/slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("/node_modules/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #000;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }
