*,
*::before,
*::after {
    box-sizing: inherit;
    outline: 0;
}

html {
    box-sizing: border-box;
    color: $cl-anthrazit-dark;
    background: $cl-anthrazit-light-05;
    font-family: $primary-font;
    font-size: 62.5%;
    font-weight: $font-weight-light;
    height: 100%;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
}

body {
    font-size: $font-size-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    line-height: $line-height-base;
    position: relative;
}

.main,
main {
    padding-bottom: $spacing-8x;
    padding-top: $spacing-8x;
    overflow-x: hidden;

    @media only screen and (min-width : $bp-l) {
        padding-bottom: 12rem;
        padding-top: 0;
    }
}

.disable-scrolling {
    @media only screen and (min-width : $bp-l) {
        overflow: hidden;
        position: fixed;
    }
}
