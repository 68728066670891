img {
    background-color: $cl-anthrazit;
    display: block;
    height: calc(9 * 90vw / 16);
    max-width: 100%;
    object-fit: cover;
}

img,
picture {
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: top;
    width: 100%;
}
