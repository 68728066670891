.banderole-container {
    position: relative;
    height: 12rem;

    @media only screen and (min-width : $bp-l) {
        height: 22rem;
    }
}

.banderole-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    transform: none !important;
}

.banderole {
    font-weight: 900;
    text-transform: uppercase;
    color: $cl-primary-00;
    font-size: 9.5vw;
    position: absolute;
    bottom: 1rem;
    overflow: hidden;
    text-align: center;
    width: 100%;
    line-height: 1;

    @media only screen and (min-width : $bp-l) {
        white-space: nowrap;
        animation: banderole 45s linear infinite;
        font-size: 17rem;
        top: 0;
    }
}

@keyframes banderole {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
    }
}
