.video-page {
    @media only screen and (min-width : $bp-l) {
        &__title {
            color: $cl-white;

            &::after {
                background-color: $cl-white;
            }
        }
    }

    &__list {
        counter-reset: section;

        li {
            color: $cl-white;
            font-size: $font-size-ml;
            margin-bottom: 1rem;
            position: relative;

            .video-list__item__title {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-weight: $font-weight-regular;
                width: fit-content;
                text-align: center;
                background-image: linear-gradient(to right, #d81300 0%, #d81300 100%);
                background-repeat: repeat-x;
                background-position: 0 90%;
                background-size: 100% 35%;
            }

            @media only screen and (min-width : $bp-l) {
                font-size: $font-size-xxl;

                &::before {
                    counter-increment: section;
                    content: counter(section) '—';
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    &::before {
                        content: '0' counter(section) ' — ';
                    }
                }

                .video-list__item__title {
                    text-align: left;
                    font-weight: $font-weight-light;
                    position: relative;
                    left: 0;
                    background-size: 100% 0%;
                    transition: all .2s ease-in-out;

                    &:hover {
                        background-image: linear-gradient(to right, #d81300 0%, #d81300 100%);
                        background-repeat: repeat-x;
                        background-position: 0 90%;
                        background-size: 100% 35%;
                    }
                }

                .video-list__item__gif {
                    display: none;
                }
            }
        }
    }

    &__background {
        display: none;
        z-index: -1;
        background: $cl-primary-00;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 6rem;
        margin-bottom: 0;

        @media only screen and (min-width : $bp-l) {
            top: 0;
            display: block;
        }

        &__img {
            opacity: 0;
            visibility: hidden;
            transition: all .2s linear;

            &--active {
                opacity: .4;
                visibility: visible;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                filter: grayscale(100%);

                @media only screen and (min-width : $bp-l) {
                    width: calc(100% - 10rem);
                }

                &::after {
                    content: '';
                    background: url('../images/noiz.gif') 50%;
                    display: block;
                    height: 100%;
                    left: 0;
                    opacity: .15;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
