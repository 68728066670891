.roor-page {
    overflow: hidden;

    .text-img-combi {
        margin-bottom: -10rem;

        &__img {
            background-color: transparent;
            padding: 5% 10% 0;
        }

        @media only screen and (min-width : $bp-l) {
            display: flex;
            flex-direction: column;
            flex-direction: row;

            &__text {
                width: 50%;
                margin-right: 10%;
            }

            &__img {
                width: 50%;
                margin-top: -25rem;
                padding: 0;
            }
        }
    }

    &__articles {
        @media only screen and (min-width : $bp-l) {
            margin-top: 25rem;
        }

        &__item {
            margin: 10rem 0;

            @media only screen and (min-width : $bp-l) {
                margin: 0;
                height: 100vh;
                max-height: 900px;
            }

            &__title-top {
                font-size: $font-size-ml;
                display: inline-block;
                transform: translateY(-55%);
                opacity: 0;

                @media only screen and (min-width : $bp-l) {
                    opacity: 1;
                }
            }

            &__description {
                margin-bottom: $spacing-4x;

                @media only screen and (min-width : $bp-l) {
                    order: 1;
                    width: 30%;
                }
            }

            &__details {
                @media only screen and (min-width : $bp-l) {
                    order: 3;
                    width: 25%;
                }

                ul {
                    margin-bottom: 4rem;
                }

                &__price {
                    font-size: $font-size-l;
                    margin-bottom: 0;
                }
            }

            &__bg {
                background: $cl-anthrazit-light-00;
            }

            &__flex-container {
                @media only screen and (min-width : $bp-l) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }

            &__image {
                padding: 0 35%;
                margin: -30% 0 $spacing-4x 0;
                transform: rotate(0deg);
                opacity: .9;

                img {
                    background: none;
                }

                @media only screen and (min-width : $bp-l) {
                    width: 20%;
                    order: 2;
                    padding: 0;
                    margin: -15% 5% -15% 20%;
                    transform: translate(-50%, 0) rotate(0deg);
                    left: 50%;
                    top: 50%;
                    z-index: 1;
                }

                @media only screen and (min-width : $bp-xl) {
                    width: 15%;
                }
            }

            @media only screen and (min-width : $bp-l) {
                &:nth-child(2) .roor-page__articles__item__image {
                    transform: translate(-50%, 0) rotate(0deg);
                }

                &:nth-child(3) .roor-page__articles__item__image {
                    transform: translate(-50%, 0) rotate(-5deg);
                }

                &:nth-child(4) .roor-page__articles__item__image {
                    transform: translate(-50%, 0) rotate(-10deg);
                }
            }
        }
    }
}
