.default-page {

    figure img {
        max-width: 90rem;
        margin-bottom: 2rem;
    }

    strong {
        font-weight: $font-weight-regular;
    }
}
